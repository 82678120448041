//Archivo Saga que ejecuta las acciones del Login

import { takeLatest, call, put } from "redux-saga/effects";
import * as dashboardActions from '../actions/DashboardAction';
import * as administradorActions from '../actions/AdministradorActions';
import { enviarFormularioCall } from "../../services/AdministradorServices";


function* enviarFormulario(action) {
    try {
        console.log("action de mandar formulario", action);
        const form = action.form;

        const data = {
            "nombre": form.nombre,
            "telefono": form.telefono,
            "direccion": form.dirección,
            "especificaciones": form.especificaciones,
        }
        console.log("data", data)
        const response = yield call(enviarFormularioCall, data);
        console.log(response);
        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El formulario se envió de forma correcta";

            yield put({ type: administradorActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: dashboardActions.ENVIAR_CONTACTO_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: administradorActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: dashboardActions.ENVIAR_CONTACTO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: administradorActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: dashboardActions.ENVIAR_CONTACTO_FAILURE });
    }
}
export function* enviarFormularioSaga() {
    yield takeLatest(dashboardActions.ENVIAR_CONTACTO_REQUEST, enviarFormulario);
}