import React, { Component } from 'react';
import { Menu, Icon, Col, Button, Row, Dropdown } from 'antd';
import '../stylesheets/Header.css';
import { Link } from 'react-router-dom';

import logo from "../assets/kali/LOGO-KC.png";

class Header extends Component {
    state = { 
        width: 0, 
        height: 0,
    };

    componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() { 

        const menu = (
            <Menu>
                <Menu.Item key="1" className="letrasMenuHeader"><a href="#Nosotros">Nosotros</a></Menu.Item>
                <Menu.Item key="2" className="letrasMenuHeader"><a href="#Registro">Registro</a></Menu.Item>
                <Menu.Item key="3" className="letrasMenuHeader"><a href="#Uso">Uso</a></Menu.Item>
                <Menu.Item key="4" className="letrasMenuHeader"><a href="#Funcionamiento">Funcionamiento</a></Menu.Item>
                <Menu.Item key="5" className="letrasMenuHeader"><a href="#Beneficios">Beneficios</a></Menu.Item>
            </Menu>
          );
        return (
            <div style={{background:'#ffffff'}}>
              {this.state.width >= 1000 ? (
                <Row className="FixedHeader" >
                <Col span={6} offset={2} >
                <a href="#"><img alt="LogoTejiendoRedes" src={logo} className="logo-responsive"/></a>
                </Col>
                <Col span={14}>
                <Menu mode="horizontal" className="menuHeader">
                <Menu.Item key="1" className="letrasMenuHeader"><a href="#Nosotros">Nosotros</a></Menu.Item>
                <Menu.Item key="2" className="letrasMenuHeader"><a href="#Registro">Registro</a></Menu.Item>
                <Menu.Item key="3" className="letrasMenuHeader"><a href="#Uso">Uso</a></Menu.Item>
                <Menu.Item key="4" className="letrasMenuHeader"><a href="#Funcionamiento">Funcionamiento</a></Menu.Item>
                <Menu.Item key="5" className="letrasMenuHeader"><a href="#Beneficios">Beneficios</a></Menu.Item>
                </Menu>
                </Col>
                
                </Row>
               ):
             (
                <div>
                    <div className="logo" />
                    <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]} className="FixedHeader">
                    <Col className="gutter-row" span={6} style={{marginTop:5, marginLeft:5}}>
                        <div>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <Button style={{ marginBottom: 16}} className="menuCelular">
                            <Icon type="menu" />
                            </Button>
                        </Dropdown>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={12} offset={1} style={{marginTop:5}}>
                        <div>
                        <a href="#"><img alt="LogoTejiendoRedes" src={logo} className="logo-responsive"/></a>
                        </div>
                    </Col>
                    </Row>
                    </div>
              )}
            </div>
        );
    }
}

export default Header;

