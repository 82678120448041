import React, { Component } from 'react';
import '../stylesheets/dashboard/dashboard.css';
import { Row, Col, Icon } from 'antd';
import imagen1 from '../assets/kali/sissa-digital.png'
import imagen2 from '../assets/kali/LOGO-KC.png'
import imagen3 from '../assets/kali/footer-imagen.png';

class Footer extends Component {
    state = { 
        width: 0, 
        height: 0,
    };

    componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() {
        return (
            <div>
             {this.state.width >= 1000 ? (
                <div>
                <Row>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Row className="fondoAzul">
                <p style={{color:'#24548e'}}>Hola</p>
                </Row>
                <br/>
                <Col lg={{offset:2, span:9}} xs={{offset:0, span:22}} className="letrasFooter" >
                    <Col lg={{span:12}} xs={{span:24}} style={{textAlign:"center"}}>
                    <p>Jose Gregorio Velasquez</p>
                        <Col xs={{span:24}}>
                            <p><Icon type="mail"  className="iconoFooter" theme="filled" />  jose.velasquez@kaliconnect.net</p>
                        </Col>
                        <Col xs={{span:24}}>
                            <p><Icon type="phone"  className="iconoFooter" theme="filled" />  +1 (786) 381 0407</p>
                        </Col>
                    </Col>
                    <Col lg={{span:12}} xs={{span:24}} style={{textAlign:"center"}}>
                    <p>Danny Rivera </p>
                        <Col xs={{span:24}}>
                            <p style={{textAlign:"center"}}><Icon type="mail" className="iconoFooter" theme="filled" />  jose.velasquez@kaliconnect.net</p>
                        </Col>
                        <Col xs={{span:24}}>
                            <p><Icon type="phone"  className="iconoFooter" theme="filled" />  +1 (786) 381 0407</p>
                        </Col>
                    </Col>
                </Col>
                <Col lg={{offset:2, span:10}} xs={{offset:1, span:23}} >
                    <Row gutter={16}>
                        <Col className="gutter-row" lg={{span:8}} xs={{span:8}}>
                            <img className="imagenFooter" src={imagen1}/>
                        </Col>
                        <Col className="gutter-row" lg={{span:8}} xs={{span:8}}>
                            <img className="imagenFooter2" src={imagen3}/>
                        </Col>
                        <Col className="gutter-row" lg={{span:8}} xs={{span:8}}>
                            <img className="imagenFooter3" src={imagen2}/>
                        </Col>
                    </Row>
                    
                </Col>
                
                </Row>
                <br/>
                <br/>
                <Col lg={{offset:2, span:9}} className="letrasGrises" xs={{offset:2}}>
                <p>SISSA ©2020 Todos los derechos reservados</p>
                </Col>
                <Col lg={{offset:6, span:6}} className="letrasGrises" xs={{offset:2}}>
                <a href="#" style={{color:'#5b5b5f'}}><p>Aviso legal y política de privacidad</p></a>
                </Col>
                <br/>
                </div>
             ):(
                <div>
                <Row>
                <br/>
                <br/>
                <Row className="fondoAzul">
                <p style={{color:'#24548e'}}>Hola</p>
                </Row>
                <br/>
                <Col lg={{offset:2, span:9}} xs={{offset:0, span:22}} className="letrasFooter" >
                    <Col lg={{span:12}} xs={{span:24}} style={{textAlign:"center"}}>
                    <p>Jose Gregorio Velasquez</p>
                        <Col xs={{span:24}}>
                            <p><Icon type="mail"  className="iconoFooter" theme="filled" />  jose.velasquez@kaliconnect.net</p>
                        </Col>
                        <Col xs={{span:24}}>
                            <p><Icon type="phone"  className="iconoFooter" theme="filled" />  +1 (786) 381 0407</p>
                        </Col>
                    </Col>
                    <Col lg={{span:12}} xs={{span:24}} style={{textAlign:"center"}}>
                    <p>Danny Rivera </p>
                        <Col xs={{span:24}}>
                            <p style={{textAlign:"center"}}><Icon type="mail" className="iconoFooter" theme="filled" />  jose.velasquez@kaliconnect.net</p>
                        </Col>
                        <Col xs={{span:24}}>
                            <p><Icon type="phone"  className="iconoFooter" theme="filled" />  +1 (786) 381 0407</p>
                        </Col>
                    </Col>
                </Col>
                <Col lg={{offset:2, span:10}} xs={{offset:1, span:23}} >
                    <Row gutter={16}>
                        <Col className="gutter-row" lg={{span:8}} xs={{span:8}}>
                            <img className="imagenFooter" src={imagen1}/>
                        </Col>
                        <Col className="gutter-row" lg={{span:8}} xs={{span:8}}>
                            <img className="imagenFooter2" src={imagen3}/>
                        </Col>
                        <Col className="gutter-row" lg={{span:8}} xs={{span:8}}>
                            <img className="imagenFooter3" src={imagen2}/>
                        </Col>
                    </Row>
                    
                </Col>
                
                </Row>
                <br/>
                <br/>
                <Col lg={{offset:2, span:9}} className="letrasGrises" xs={{offset:2}}>
                <p>SISSA ©2020 Todos los derechos reservados</p>
                </Col>
                <Col lg={{offset:6, span:6}} className="letrasGrises" xs={{offset:2}}>
                <a href="#" style={{color:'#5b5b5f'}}><p>Aviso legal y política de privacidad</p></a>
                </Col>
                <br/>  
                </div>
             )}
            </div>

        );

    }

}



export default Footer;