export const OBTENER_TIPOS_ELEMENTOS_REQUEST = 'OBTENER_TIPOS_ELEMENTOS_REQUEST';
export const OBTENER_TIPOS_ELEMENTOS_SUCCESS = 'OBTENER_TIPOS_ELEMENTOS_SUCCESS';
export const OBTENER_TIPOS_ELEMENTOS_FAILURE = 'OBTENER_TIPOS_ELEMENTOS_FAILURE';

export const OBTENER_ELEMENTOS_REQUEST = 'OBTENER_ELEMENTOS_REQUEST';
export const OBTENER_ELEMENTOS_SUCCESS = 'OBTENER_ELEMENTOS_SUCCESS';
export const OBTENER_ELEMENTOS_FAILURE = 'OBTENER_ELEMENTOS_FAILURE';

export const CREAR_ELEMENTO_REQUEST = 'CREAR_ELEMENTO_REQUEST';
export const CREAR_ELEMENTO_SUCCESS = 'CREAR_ELEMENTO_SUCCESS';
export const CREAR_ELEMENTO_FAILURE = 'CREAR_ELEMENTO_FAILURE';

export const EDITAR_ELEMENTO_REQUEST = 'EDITAR_ELEMENTO_REQUEST';
export const EDITAR_ELEMENTO_SUCCESS = 'EDITAR_ELEMENTO_SUCCESS';
export const EDITAR_ELEMENTO_FAILURE = 'EDITAR_ELEMENTO_FAILURE';

export const BORRAR_ELEMENTO_REQUEST = 'BORRAR_ELEMENTO_REQUEST';
export const BORRAR_ELEMENTO_SUCCESS = 'BORRAR_ELEMENTO_SUCCESS';
export const BORRAR_ELEMENTO_FAILURE = 'BORRAR_ELEMENTO_FAILURE';

export const OPEN_NUEVO_ELEMENTO_MODAL = 'OPEN_NUEVO_ELEMENTO_MODAL';
export const OPEN_EDITAR_ELEMENTO_MODAL = 'OPEN_EDITAR_ELEMENTO_MODAL';
export const ELEMENTO_DETALLE = 'ELEMENTO_DETALLE';

export const SHOW_ERROR_MSG = "SHOW_ERROR_MSG";
export const SHOW_SUCCESS_MSG = "SHOW_SUCCESS_MSG";

// ****** NOTAS ******** //
export const CREAR_NOTA_REQUEST = 'CREAR_NOTA_REQUEST';
export const CREAR_NOTA_SUCCESS = 'CREAR_NOTA_SUCCESS';
export const CREAR_NOTA_FAILURE = 'CREAR_NOTA_FAILURE';

export const GET_NOTAS_REQUEST = 'GET_NOTAS_REQUEST';
export const GET_NOTAS_SUCCESS = 'GET_NOTAS_SUCCESS';
export const GET_NOTAS_FAILURE = 'GET_NOTAS_FAILURE';

export const GET_NOTAS_CATEGORIA_REQUEST = 'GET_NOTAS_CATEGORIA_REQUEST';
export const GET_NOTAS_CATEGORIA_SUCCESS = 'GET_NOTAS_CATEGORIA_SUCCESS';
export const GET_NOTAS_CATEGORIA_FAILURE = 'GET_NOTAS_CATEGORIA_FAILURE';

export const GET_NOTA_ESPECIFICA_REQUEST = 'GET_NOTA_ESPECIFICA_REQUEST';
export const GET_NOTA_ESPECIFICA_SUCCESS = 'GET_NOTA_ESPECIFICA_SUCCESS';
export const GET_NOTA_ESPECIFICA_FAILURE = 'GET_NOTA_ESPECIFICA_FAILURE';


export const CHANGE_CATEGORIA = 'CHANGE_CATEGORIA';

export const ELIMINAR_NOTA_REQUEST = 'ELIMINAR_NOTA_REQUEST';
export const ELIMINAR_NOTA_SUCCESS = 'ELIMINAR_NOTA_SUCCESS';
export const ELIMINAR_NOTA_FAILURE = 'ELIMINAR_NOTA_FAILURE';

export const SHOW_EDITAR_NOTA_MODAL = "SHOW_EDITAR_NOTA_MODAL";
export const GET_INFO_NOTA_REQUEST = "GET_INFO_NOTA_REQUEST";
export const GET_INFO_NOTA_SUCCESS = "GET_INFO_NOTA_SUCCESS";

export const EDITAR_NOTA_REQUEST = "EDITAR_NOTA_REQUEST";
export const EDITAR_NOTA_SUCCESS = "EDITAR_NOTA_SUCCESS";
export const EDITAR_NOTA_FAILURE = "EDITAR_NOTA_FAILURE";


// ****** NOTAS  POR CATEGORIA ******** //

export const GET_CATEGORIAS_REQUEST = "GET_CATEGORIAS_REQUEST";
export const GET_CATEGORIAS_SUCCESS = "GET_CATEGORIAS_SUCCESS";
export const GET_CATEGORIAS_FAILURE = "GET_CATEGORIAS_FAILURE";

export const SHOW_NUEVA_CATEGORIA_MODAL = "SHOW_NUEVA_CATEGORIA_MODAL";
export const SHOW_EDITAR_CATEGORIA_MODAL = "SHOW_EDITAR_CATEGORIA_MODAL"

export const CREAR_CATEGORIA_REQUEST = "CREAR_CATEGORIA_REQUEST";
export const CREAR_CATEGORIA_SUCCESS = "CREAR_CATEGORIA_SUCCESS";
export const CREAR_CATEGORIA_FAILURE = "CREAR_CATEGORIA_FAILURE";

export const EDITAR_CATEGORIA_REQUEST = "EDITAR_CATEGORIA_REQUEST";
export const EDITAR_CATEGORIA_SUCCESS = "EDITAR_CATEGORIA_SUCCESS";
export const EDITAR_CATEGORIA_FAILURE = "EDITAR_CATEGORIA_FAILURE";

export const ELIMINAR_CATEGORIA_REQUEST = "ELIMINAR_CATEGORIA_REQUEST";
export const ELIMINAR_CATEGORIA_SUCCESS = "ELIMINAR_CATEGORIA_SUCCESS";
export const ELIMINAR_CATEGORIA_FAILURE = "ELIMINAR_CATEGORIA_FAILURE";

export const BUSCAR_NOTA_REQUEST = "BUSCAR_NOTA_REQUEST";
export const BUSCAR_NOTA_SUCCESS = "BUSCAR_NOTA_SUCCESS";
export const BUSCAR_NOTA_FAILURE = "BUSCAR_NOTA_FAILURE";

export const BUSCAR_NOTA_CATEGORIAS_REQUEST = "BUSCAR_NOTA_CATEGORIAS_REQUEST";
export const BUSCAR_NOTA_CATEGORIAS_SUCCESS = "BUSCAR_NOTA_CATEGORIAS_SUCCESS";
export const BUSCAR_NOTA_CATEGORIAS_FAILURE = "BUSCAR_NOTA_CATEGORIAS_FAILURE";

