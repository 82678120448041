import React, { Component } from 'react';
import { Col,Row, Card} from 'antd';
import '../../stylesheets/dashboard/dashboard.css';
import celular1 from '../../assets/kali/celularPantalla1.png';
import celular2 from '../../assets/kali/celularPantalla2.png';
import celular3 from '../../assets/kali/celularPantalla3.png';

class Pantallas extends Component {
    render() { 
        return (
            <div>
             <br/>
             <br/>
             <p className="tituloQuienes">Pantallas de consulta Kali Connect</p>
             <br/>
             <Col lg={{offset:6, span:14}}>
             <Row type="flex" justify="center">
                <Col className="gutter-row" lg={{span:8}} xs={{span:23}}>
                    <div className="gutter-box">
                    <p className="letrasPantallas">Historial y Consultas de Alertas</p>
                    <img src={celular1} className="celularPantallas"/>
                    </div>
                </Col>
                <Col className="gutter-row" lg={{span:8}} xs={{span:23}}>
                    <div className="gutter-box">
                    <p className="letrasPantallas" style={{marginLeft:45}}>Menú de la App</p>
                    <img src={celular2} className="celularPantallas"/>
                    </div>
                </Col>
                <Col className="gutter-row" lg={{span:8}} xs={{span:23}}>
                    <div className="gutter-box">
                    <p className="letrasPantallas">Blog de Notas (imagen adjunta)</p>
                    <img src={celular3} className="celularPantallas"/>
                    </div>
                </Col>
             </Row>
             </Col>
            </div>
        );
    }
}


export default Pantallas;

