import React, { Component } from 'react';
import { Col,Row, Card} from 'antd';
import '../../stylesheets/dashboard/dashboard.css';
import logo from '../../assets/kali/que-es-foto.png';
import logo2 from '../../assets/kali/celular.png'

class QuienesSomos extends Component {
    render() { 
        return (
            <div>
            <Row className="">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
                <p className="tituloQuienes">¿Qué es Kali Connect?</p>
                <Col xs={{span:24}} lg={{span:8, offset:4}}>
                    <Card className="quienesCard">
                    <p className="letrasQuienes">
                    Es una aplicación móvil multifuncional de <span style={{fontWeight:'bolder'}}>protección y seguridad </span>
                    encargada de brindar a sus usuarios un servicio de comunicación directa y confidencial con la empresa de seguridad 
                    o administración las 24/7, en tiempo real y desde cualquier lugar donde se encuentre el cliente logrando así 
                    visualizar su ubicación (GPS) desde la sala de monitoreo.
                    </p>
                    </Card>
                </Col>
                <Col xs={{span:19, offset:3}} lg={{span:12, offset:0}} >
                    <img alt="QuienesSomos" src={logo} className="logo-quienes"/>
                </Col>
            </Row>
            <br/>
            <br/>
            <Row className="cuadroAzul">
                <div className="atras">
                <Col lg={{span:22, offset:1}}>
                <Card className="quienesCard2">
                    <Col xs={{span:24}} lg={{span:8, offset:1}} style={{textAlign:"center"}} >
                        <div className="adelante">
                            <img alt="QuienesSomos2" src={logo2} className="logo-quienes2"/>
                        </div>
                    </Col>
                    
                    <Col xs={{span:24}} lg={{span:14, offset:1}}>
                        <p className="letrasQuienes2">
                        Esta novedosa herramienta no solo permite el envío de <span style={{fontWeight:'bolder', color:'#ff343b'}}>Alertas de Pánico S.O.S. </span>
                        en caso de emergencias, sino que, al ser multifuncional, los usuarios pueden enviar mensajes para reportar situaciones de riesgo,
                        hacer notificaciones y solicitar cualquier requerimiento en general.
                        </p>
                    </Col>
                </Card>
                </Col>
                </div>
            </Row>
                
            </div>
        );
    }
}


export default QuienesSomos;

