import * as dashboardActions from '../actions/DashboardAction';

const initialState = {

    
}

export default (state = initialState, action) => {
    switch (action.type) {
        
        case dashboardActions.ENVIAR_CONTACTO_REQUEST:
            return { ...state };
        case dashboardActions.ENVIAR_CONTACTO_SUCCESS:
            return { ...state };
        case dashboardActions.ENVIAR_CONTACTO_FAILURE:
            return { ...state };

        default:
            return state;
    }
};