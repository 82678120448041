import React, { Component } from 'react';
import Header from '../Components/HeaderHome';
import Alerta from '../Components/Alerta';
import Footer from '../Components/Footer';
import QuienesSomos from '../Components/Dashboard/QuienesSomos';
import RegistroUsuario from '../Components/Dashboard/RegistroUsuario';
import PantallasKali from '../Components/Dashboard/PantallasKali';


class DashboardContainer extends Component {
    state = { 
        width: 0, 
        height: 0,
    };

    componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() { 
        return (
            <div>
             {this.state.width >= 1000 ? (
            <div>
            <Alerta/>
            <Header/>
            <QuienesSomos/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <RegistroUsuario/>
            <PantallasKali/>
            <Footer/>
            </div>
             ):(
                <div>
            <Alerta/>
            <Header/>
            <QuienesSomos/>
            <RegistroUsuario/>
            <PantallasKali/>
            <Footer/>
            </div>
             )}
             </div>
        );
    }
}


export default DashboardContainer;

