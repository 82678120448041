import React, { Component } from 'react';
import { Col,Row, Card} from 'antd';
import '../../stylesheets/dashboard/dashboard.css';
import logo from '../../assets/kali/registroImagenMini.png'
import cel from '../../assets/kali/celular2-sinfondo.png';
import registro from '../../assets/kali/registro.jpg';
import escritorio from '../../assets/kali/escritorio.png';

class RegistroUsuario extends Component {
    state = { 
        width: 0, 
        height: 0,
    };

    componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    render() { 
        return (
            <div>
                <br/>
                <br/>
                <p className="tituloQuienes">Registro de Usuario Kali Connect</p>
                <Row className="">
                    <Col xs={{span:17, offset:5}} lg={{span:3, offset:4}} >
                    <img alt="QuienesSomos" src={logo} className="logo-quienes3"/>
                    </Col>
                    <Col xs={{span:22, offset:1}} lg={{span:14, offset:0}}>
                        <p className="letrasRegistro">
                        El sistema comienza a funcionar cuando el usuario se registra en la plataforma <span style={{fontWeight:'bolder'}}>Kali Web  </span><a href="https://demo.kaliconnect.net/">(https://demo.kaliconnect.net/)</a>
                        . Enseguida el usuario deberá descargar la aplicación App Kali en su dispositivo móvil para iniciar sesión.
                        </p>
                    </Col>
                </Row>
                    <Row>
                        <Col xs={{span:22, offset:1}} lg={{span:10, offset:4}} style={{marginTop:1+'em'}}>
                        <img src={registro} className="imagenRegistro"/>
                        </Col>
                        <Col lg={{span:4, offset:1}} xs={{span:17, offset:8}}>
                        <img src={cel} className="celularRegistro"/>
                        </Col>
                    </Row>
                <Row>
                    <Col xs={{span:17, offset:5}} lg={{span:4, offset:4}} >
                    <img src={escritorio} className="escritorioRegistro"/>
                    </Col>
                    <Col xs={{span:22, offset:1}} lg={{span:12, offset:1}}>
                        <p className="letrasRegistro2">
                        Kali Connect brinda servicio de software a centros de monitoreo, los cuales son los encargados de administrar las alertas y mensajes de la plataforma.
                        </p>
                    </Col>
                </Row>
            </div>
        );
    }
}


export default RegistroUsuario;

